import {
  // createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
// import { RootState, AppThunk } from "../../app/store";
import { DeveloperLogin_developerLogin } from "../../graphql/mutations/__generated__/DeveloperLogin";

export type UserState = DeveloperLogin_developerLogin | null;

const initialState: UserState = {
  __typename: "DeveloperAuth",
  token: null,
  developer: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<UserState>) => {
      if (action.payload) {
        state.token = action.payload.token;
        state.developer = action.payload.developer;
      }
    },
    unSetUser: (state) => {
      state = initialState;
    },
  },
});

export const { setUser, unSetUser } = userSlice.actions;

export default userSlice.reducer;
