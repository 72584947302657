import { gql } from "@apollo/client";

export const createDeveloper = gql`
  mutation CreateDeveloper($input: DeveloperInput) {
    createDeveloper(input: $input)
  }
`;

export const developerLogin = gql`
  mutation DeveloperLogin($email: String!, $password: String!) {
    developerLogin(email: $email, password: $password) {
      token
      developer {
        id
        name
        email
      }
    }
  }
`;

export const createApp = gql`
  mutation CreateApp($input: AppInput) {
    createApp(input: $input)
  }
`;
