export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const getInitials = (name: string) => {
  const fullName = name.split(" ");
  const initials =
    fullName.length > 0
      ? (((fullName?.shift()?.charAt(0) as string) +
          fullName?.pop()?.charAt(0)) as string)
      : "";
  return initials.toUpperCase();
};
