import { gql } from "@apollo/client";

export const getApps = gql`
  query GetApps($pagination: Pagination) {
    getApps(pagination: $pagination) {
      id
      name
      description
      token
    }
  }
`;
