import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import { setUser } from "../../features/user/userSlice";
import { TOKEN_str } from "../../graphql/client";
import { developerLogin } from "../../graphql/mutations/createDeveloper";
import {
  DeveloperLogin,
  DeveloperLoginVariables,
  DeveloperLogin_developerLogin,
} from "../../graphql/mutations/__generated__/DeveloperLogin";

type Props = {};

const Login = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation() as Record<string, any>;
  const message = state?.message;
  const { register, handleSubmit } = useForm<DeveloperLoginVariables>();
  const [login, { loading }] = useMutation<
    DeveloperLogin,
    DeveloperLoginVariables
  >(developerLogin);

  const onSubmit = (data: DeveloperLoginVariables) => {
    login({ variables: data })
      .then(({ data }) => {
        dispatch(
          setUser(data?.developerLogin as DeveloperLogin_developerLogin)
        );
        if (data?.developerLogin?.token) {
          localStorage.setItem(
            TOKEN_str,
            data?.developerLogin?.token as string
          );
          navigate("/app/", {});
        }
      })
      .catch(() => {});
  };

  return (
    <>
      <div>
        <img
          className="h-12 w-auto rounded-md"
          src="https://d3ug0vbiixnxyq.cloudfront.net/09mclqx0lcwgki2dit37soe-3-main-asset.jpeg"
          alt="Workflow"
        />
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
          Sign in to your Tendo Developer account
        </h2>
        <p className="mt-2 text-sm text-gray-600">
          Or{" "}
          <Link
            to="/register"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            register a free developer account
          </Link>
        </p>

        {message && (
          <div className="p-1 my-2 text-sm px-3 rounded-md bg-green-200 text-green-600">
            {message}
          </div>
        )}
      </div>

      <div className="mt-8">
        <div className="mt-6">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  {...register("email", { required: "Required" })}
                  type="email"
                  autoComplete="email"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="space-y-1">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  {...register("password", { required: "Required" })}
                  type="password"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="flex items-center justify-end">
              <div className="text-sm">
                <Link
                  to="forgot-password"
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loading}
                className="w-full flex disabled:bg-gray-500 disabled:cursor-not-allowed justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Login;
