import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
export const TOKEN_KEY = "token";
const URL =
  process.env.NODE_ENV === "development"
    ? "https://api.main.gettenga.app/graph"
    : "https://api.main.gettenga.app/graph";
export const TOKEN_str = "soko-token-web";

const httpLink = createHttpLink({
  uri: URL,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem(TOKEN_str);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      "soko-web-token": token ? token : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export { client };
