import { useMutation } from "@apollo/client";
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { useForm } from "react-hook-form";
import { createApp } from "../graphql/mutations/createDeveloper";
import {
  CreateApp,
  CreateAppVariables,
} from "../graphql/mutations/__generated__/CreateApp";
import { getApps } from "../graphql/queries";

type ButtonComponentProps = {
  onPress: () => void;
};

type Props = {
  buttonComponent?: (props: ButtonComponentProps) => React.ReactNode;
};

const CreateNewApp = ({ buttonComponent }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const { register, handleSubmit } = useForm<CreateAppVariables>();
  const closeModal = () => setShowModal(false);
  const [create, { loading }] = useMutation<CreateApp, CreateAppVariables>(
    createApp,
    {
      refetchQueries: [
        {
          query: getApps,
        },
      ],
    }
  );

  const onSubmit = (data: CreateAppVariables) => {
    console.log(data);
    create({ variables: data })
      .then(() => {
        setShowModal(false);
      })
      .catch(() => {});
  };

  return (
    <Fragment>
      {buttonComponent ? (
        buttonComponent({
          onPress: () => setShowModal(true),
        })
      ) : (
        <button onClick={() => setShowModal(true)}>Create new app</button>
      )}

      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md"
              >
                <div className="grid grid-cols-1 gap-3">
                  <div className="flex flex-col">
                    <label
                      htmlFor="Name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      className="mt-1 w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md flex placeholder:text-gray-400"
                      placeholder="Name"
                      {...register("input.name", { required: "Required" })}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Country
                    </label>
                    <select
                      id="country"
                      name="country"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      defaultValue="Canada"
                    >
                      <option>Ghana</option>
                      <option>Nigeria</option>
                    </select>
                  </div>
                  <div className="flex flex-col">
                    <label
                      htmlFor="Description"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Description
                    </label>
                    <textarea
                      placeholder="Tell us about your app"
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md placeholder:text-gray-400"
                      {...register("input.description", {
                        required: "Required",
                      })}
                    ></textarea>
                  </div>
                  <div className="flex justify-end">
                    <button
                      disabled={loading}
                      type="submit"
                      className="p-2 disabled:bg-gray-500 bg-blue-600 text-white rounded-md px-4 hover:bg-blue-800"
                    >
                      Add app
                    </button>
                  </div>
                </div>
              </form>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </Fragment>
  );
};

export default CreateNewApp;
