import { useMutation } from "@apollo/client";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { createDeveloper } from "../../graphql/mutations/createDeveloper";
import {
  CreateDeveloper,
  CreateDeveloperVariables,
} from "../../graphql/mutations/__generated__/CreateDeveloper";

type Props = {};

const Register = (props: Props) => {
  const navigation = useNavigate();
  const {
    register,
    handleSubmit,
    // formState: { errors },
    // watch,
  } = useForm<CreateDeveloperVariables>();
  const [create, { loading }] = useMutation<
    CreateDeveloper,
    CreateDeveloperVariables
  >(createDeveloper);

  const onSubmit = (data: CreateDeveloperVariables) => {
    create({ variables: data })
      .then(() => {
        navigation("/", {
          replace: true,
          state: {
            message:
              "Registration was Successful, Continue to the dashboard by logging into your account ",
          },
        });
      })
      .catch(() => {});
  };

  return (
    <>
      <div>
        <img
          className="h-12 w- rounded-md"
          src="https://d3ug0vbiixnxyq.cloudfront.net/09mclqx0lcwgki2dit37soe-3-main-asset.jpeg"
          alt="Workflow"
        />
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
          Register a free Tendo Developer account
        </h2>
        <p className="mt-2 text-sm text-gray-600">
          Or{" "}
          <Link
            to="/"
            className="font-medium text-indigo-600 hover:text-indigo-500"
          >
            Sign in to your account
          </Link>
        </p>
      </div>

      <div className="mt-8">
        <div className="mt-6">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Full Name
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  {...register("input.name", { required: true })}
                  type="text"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  {...register("input.email", { required: true })}
                  type="email"
                  autoComplete="email"
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div className="space-y-1">
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  {...register("input.password", { required: true })}
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loading}
                className="w-full disabled:bg-gray-600 disabled:cursor-not-allowed flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Register
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Register;
