import { useQuery } from "@apollo/client";
import { getApps } from "../../../graphql/queries";
import {
  GetApps,
  GetAppsVariables,
} from "../../../graphql/queries/__generated__/GetApps";

export const useApps = (variables?: GetAppsVariables) => {
  const { data, loading, error } = useQuery<GetApps, GetAppsVariables>(
    getApps,
    {
      variables,
    }
  );
  return { apps: data?.getApps, loading, error };
};
