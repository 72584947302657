import { Dialog, Transition } from "@headlessui/react";
import {
  DuplicateIcon,
  EyeIcon,
  EyeOffIcon,
  XIcon,
} from "@heroicons/react/outline";
import React, { Fragment, useRef, useState } from "react";

type Props = {
  showModal: boolean;
  closeModal: () => void;
  project: Record<string, any>;
};

const ViewToken = ({ closeModal, showModal, project }: Props) => {
  const [showToken, setShowToken] = useState(false);
  const [copied, setCopied] = useState(false);
  const tokenRef = useRef<HTMLInputElement>(null);

  const revealToken = () => {
    const ref = tokenRef?.current;
    if (ref) {
      setShowToken(true);
      ref.type = "text";
      setTimeout(() => {
        ref.type = "password";
        setShowToken(false);
      }, 500);
    } else {
      console.log("tokenRef is null");
    }
  };

  const copyToken = () => {
    const ref = tokenRef?.current;
    if (ref) {
      ref.select();
      navigator.clipboard.writeText(ref.value);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1000);
    } else {
      console.log("tokenRef is null");
    }
  };
  return (
    <>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={closeModal}
        >
          <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md">
                <div className="flex flex-col">
                  <div className="flex justify-end">
                    <span
                      className="text-sm bg-slate-200 p-1 rounded-md cursor-pointer"
                      onClick={closeModal}
                    >
                      <XIcon className="h-4 w-4" />
                    </span>
                  </div>
                  <div className="border-0">
                    <label
                      htmlFor="name"
                      className="block text-lg font-medium text-gray-900"
                    >
                      {project?.name}
                    </label>
                  </div>
                  <div className="border-0 my-4">
                    <label
                      htmlFor="name"
                      className="block text-xs font-medium text-gray-500"
                    >
                      Country
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-base"
                      value={project?.country ?? "Ghana"}
                      disabled
                    />
                  </div>
                  <div className="border-0 my-4">
                    <label
                      htmlFor="description"
                      className="block text-xs font-medium text-gray-500"
                    >
                      Description
                    </label>
                    <input
                      type="text"
                      name="description"
                      id="description"
                      className="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-base"
                      placeholder="Jane Doe"
                      value={project?.description}
                      disabled
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="token"
                      className="block text-sm font-medium text-gray-700"
                    >
                      API token
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <div className="relative flex items-stretch flex-grow focus-within:z-10">
                        <input
                          type="password"
                          name="token"
                          id="token"
                          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded-none rounded-l-md pl-3 pr-12 sm:text-sm border-gray-300"
                          placeholder="John Doe"
                          value={project?.token}
                          ref={tokenRef}
                        />
                        <div
                          className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                          onClick={revealToken}
                        >
                          {showToken ? (
                            <EyeOffIcon
                              className="h-5 w-5 text-gray-400 cursor-pointer"
                              aria-hidden="true"
                            />
                          ) : (
                            <EyeIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          )}
                        </div>
                      </div>
                      <button
                        type="button"
                        className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
                        onClick={copyToken}
                      >
                        <DuplicateIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span>{copied ? "Copied" : "Copy"}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ViewToken;
